




























































import { computed, defineComponent } from "@vue/composition-api";
import { toUserDateTimeFormat } from "@/apps/core/modules/utils/datetime";
import { ModalProgrammatic as modal } from "buefy";
import { formatRupiah } from "@/apps/core/modules/utils/text";
import { isNasabah, isPengelola } from "@/apps/accounts/modules/store";

export default defineComponent({
  name: "SetoranListBox",
  props: {
    setoran: { type: Object, required: true },
  },
  setup(props) {
    const imgUrl = computed(() => {
      if (props.setoran) {
        const jenis = props.setoran.jenis.toLowerCase();
        return require(`@/apps/tabungan/assets/img/simp_${jenis}.png`);
      }
      return require("@/apps/tabungan/assets/img/simp_pokok.png");
    });

    const showImage = () => {
        return modal.open(
          `<p class="image"><img src="${props.setoran.bukti}"></p>`
        );
    }
    return {
      // Data
      detailUrl: "/setoran",

      // Computed
      imgUrl,
      isNasabah,
      isPengelola,

      // Method
      formatRupiah,
      showImage,
      toUserDateTimeFormat,
    };
  },
});
